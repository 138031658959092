import Link from 'next/link';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

import styles from './styles.module.scss';
import { Events, trackEvent } from '@/utils/analytics';

interface Props {
    showTitle?: boolean;
}

const MainLinks: React.FC<Props> = ({ showTitle = true }) => {
    return (
        <div>
            {showTitle ? <h4 className={cx('__col-header')}>FestGPS</h4> : null}
            <ul className={cx('__col-links')}>
                <li>
                    <Link href='/' className={cx('__col-link')}>
                        Home
                    </Link>
                </li>
                <li>
                    <Link href='/about' className={cx('__col-link')}>
                        About
                    </Link>
                </li>
                <li>
                    <Link href='/festivals' className={cx('__col-link')}>
                        Festivals
                    </Link>
                </li>
                <li>
                    <Link href='/artists' className={cx('__col-link')}>
                        Artists
                    </Link>
                </li>
                <li>
                    <Link href='/locations' className={cx('__col-link')}>
                        Locations
                    </Link>
                </li>
                <li>
                    <Link href='/genres' className={cx('__col-link')}>
                        Genres
                    </Link>
                </li>
                <li>
                    <Link href='/blog' className={cx('__col-link')}>
                        Fest Digest
                    </Link>
                </li>
                <li>
                    <Link
                        href='https://links.festgps.app/download'
                        className={cx('__col-link')}
                        onClick={() =>
                            trackEvent(Events.ClickedGetTheApp, {
                                footer: true,
                                path: window.location.pathname,
                            })
                        }>
                        Get the app
                    </Link>
                </li>
            </ul>
        </div>
    );
};

export default MainLinks;
