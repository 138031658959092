import Link from 'next/link';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

import styles from './styles.module.scss';

interface Props {
    showTitle?: boolean;
}

const FollowUs: React.FC<Props> = ({ showTitle = true }) => {
    return (
        <div>
            {showTitle ? (
                <h4 className={cx('__col-header')}>Follow Us</h4>
            ) : null}
            <ul className={cx('__col-links')}>
                <li>
                    <Link
                        href='https://www.instagram.com/festgps/'
                        target='_blank'
                        rel='noopener'
                        className={cx('__col-link')}>
                        Instagram
                    </Link>
                </li>
                <li>
                    <Link
                        href='https://www.facebook.com/FestGPS'
                        target='_blank'
                        rel='noopener'
                        className={cx('__col-link')}>
                        Facebook
                    </Link>
                </li>
                <li>
                    <Link
                        href='https://twitter.com/FestGPS'
                        target='_blank'
                        rel='noopener'
                        className={cx('__col-link')}>
                        Twitter
                    </Link>
                </li>
            </ul>
        </div>
    );
};

export default FollowUs;
