import { useEffect, useState } from 'react';
import Link from 'next/link';
import classNames from 'classnames/bind';

import useHTTP from '@/hooks/useHTTP';

const cx = classNames.bind(styles);

import styles from './styles.module.scss';

interface Props {
    maxResults: number;
    showTitle?: boolean;
}

const FeaturedGenres: React.FC<Props> = ({ maxResults, showTitle = true }) => {
    const [results, setResults] = useState<Genre[]>([]);

    const { data, loading, error } = useHTTP({
        request: {
            endpoint: '/genres',
            requestKey: 'footer-featured-genres',
            params: {
                _sort: 'name:ASC',
                featured_eq: true,
            },
        },
        method: 'get',
    });

    useEffect(() => {
        if (!loading && !error && data) {
            const genres: Genre[] = data?.slice(0, maxResults);
            setResults(genres);
        }
    }, [data, error, loading, maxResults]);

    return results?.length > 0 ? (
        <div>
            {showTitle ? (
                <h4 className={cx('__col-header')}>Popular Music Genres</h4>
            ) : null}
            <ul className={cx('__col-links')}>
                {results.map((result: Genre) => (
                    <li key={`footer-genre-${result.id}`}>
                        <Link
                            href={`/festivals/genre/${result.slug}`}
                            className={cx('__col-link')}>
                            {result.name}
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    ) : null;
};

export default FeaturedGenres;
