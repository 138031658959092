import { useEffect, useState } from 'react';
import Link from 'next/link';
import classNames from 'classnames/bind';

import useHTTP from '@/hooks/useHTTP';

const cx = classNames.bind(styles);

import styles from './styles.module.scss';

interface Props {
    maxResults: number;
    showTitle?: boolean;
}

const TouringArtists: React.FC<Props> = ({ maxResults, showTitle = true }) => {
    const [results, setResults] = useState<Artist[]>([]);

    const { data, loading, error } = useHTTP({
        request: {
            endpoint: '/artists/touring',
            cache: true,
            params: {
                _limit: maxResults,
            },
        },
        method: 'get',
    });

    useEffect(() => {
        if (!loading && !error && data) {
            const artists: Artist[] = data?.data;
            setResults(artists);
        }
    }, [data, error, loading]);

    return results?.length > 0 ? (
        <div>
            {showTitle ? (
                <h4 className={cx('__col-header')}>Touring Artists</h4>
            ) : null}
            <ul className={cx('__col-links')}>
                {results.map((result: Artist) => (
                    <li key={`footer-artist-${result.id}`}>
                        <Link
                            href={`/artists/${result.slug}`}
                            className={cx('__col-link')}>
                            {result.name}
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    ) : null;
};

export default TouringArtists;
