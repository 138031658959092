import Link from 'next/link';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

import styles from './styles.module.scss';

interface Props {
    authenticated?: boolean;
    showTitle?: boolean;
}

const Support: React.FC<Props> = ({ authenticated, showTitle = true }) => {
    return (
        <div>
            {showTitle ? <h4 className={cx('__col-header')}>Support</h4> : null}
            <ul className={cx('__col-links')}>
                <li>
                    <Link
                        href={
                            authenticated
                                ? '/user/feedback?r=suggest_festival'
                                : '/auth/sign-in'
                        }
                        className={cx('__col-link')}>
                        Suggest a Festival
                    </Link>
                </li>
                <li>
                    <Link
                        href={
                            authenticated
                                ? '/user/feedback?r=bug'
                                : '/auth/sign-in'
                        }
                        className={cx('__col-link')}>
                        Report an issue
                    </Link>
                </li>
                <li>
                    <Link
                        href={
                            authenticated
                                ? '/user/feedback?r=other'
                                : '/auth/sign-in'
                        }
                        className={cx('__col-link')}>
                        General feedback
                    </Link>
                </li>
                <li>
                    <Link href='/privacy-policy' className={cx('__col-link')}>
                        Privacy policy
                    </Link>
                </li>
                <li>
                    <Link
                        href='/terms-and-conditions'
                        className={cx('__col-link')}>
                        Terms and Conditions
                    </Link>
                </li>
                <li>
                    <Link href='/legal' className={cx('__col-link')}>
                        Legal
                    </Link>
                </li>
            </ul>
        </div>
    );
};

export default Support;
