import Link from 'next/link';

import className from 'classnames/bind';

import styles from './styles.module.scss';
import { ReactElement } from 'react';

const cx = className.bind(styles);

interface Props {
    h1?: string;
    h2?: string;
    h3?: string;
    tag?: string;
    subHeading?: string;
    children?: ReactElement;
    divider?: boolean;
    h1Href?: string;
    h2Href?: string;
    h3Href?: string;
    theme?: ElementTheme;
    customClass?: string;
    textAlign?: 'left' | 'center' | 'right';
}

export default function PageHeader({
    h1,
    h2,
    h3,
    tag,
    subHeading,
    children,
    divider = false,
    h1Href,
    h2Href,
    h3Href,
    theme = 'white',
    textAlign = 'left',
    customClass,
}: Props) {
    return (
        <div
            className={cx(
                'page-header',
                `page-header--${theme}`,
                `page-header--${textAlign}`,
                {
                    'page-header--with-divider': divider,
                },
                { 'page-header--with-children': children },
                customClass
            )}>
            {tag ? <span className={cx('__tag')}>{tag}</span> : null}
            {h1 ? (
                !h1Href ? (
                    <h1 className={cx('heading')}>{h1}</h1>
                ) : (
                    <Link href={h1Href}>
                        <h1 className={cx('heading', 'heading--link')}>{h1}</h1>
                    </Link>
                )
            ) : null}
            {h2 ? (
                !h2Href ? (
                    <h2 className={cx('heading--2')}>{h2}</h2>
                ) : (
                    <Link href={h2Href}>
                        <h2 className={cx('heading--2', 'heading--link')}>
                            {h2}
                        </h2>
                    </Link>
                )
            ) : null}
            {h3 ? (
                !h3Href ? (
                    <h3 className={cx('heading--3')}>{h3}</h3>
                ) : (
                    <Link href={h3Href}>
                        <h3 className={cx('heading--2', 'heading--link')}>
                            {h3}
                        </h3>
                    </Link>
                )
            ) : null}
            {subHeading ? (
                <p className={cx('sub-heading')}>{subHeading}</p>
            ) : null}
            {children ? (
                <div className={cx('__children')}>{children}</div>
            ) : null}
        </div>
    );
}
