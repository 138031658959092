import Link from 'next/link';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

import styles from './styles.module.scss';

interface Props {
    showTitle?: boolean;
}

const Account: React.FC<Props> = ({ showTitle = true }) => {
    return (
        <div>
            {showTitle ? <h4 className={cx('__col-header')}>Account</h4> : null}
            <ul className={cx('__col-links')}>
                <li>
                    <Link href='/auth/sign-in' className={cx('__col-link')}>
                        Sign In
                    </Link>
                </li>
                <li>
                    <Link href='/auth/sign-up' className={cx('__col-link')}>
                        Sign Up
                    </Link>
                </li>
            </ul>
        </div>
    );
};

export default Account;
