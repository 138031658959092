import { useEffect, useState } from 'react';
import Link from 'next/link';
import classNames from 'classnames/bind';

import useHTTP from '@/hooks/useHTTP';

const cx = classNames.bind(styles);

import styles from './styles.module.scss';

interface Props {
    maxResults: number;
    showTitle?: boolean;
}

const UpcomingFestivals: React.FC<Props> = ({
    maxResults,
    showTitle = true,
}) => {
    const [results, setResults] = useState<Festival[]>([]);

    const { data, loading, error } = useHTTP({
        request: {
            endpoint: '/festivals',
            cache: true,
            params: {
                _limit: maxResults,
                _filter: { upcoming: 'ASC' },
                featured_eq: false,
            },
            requestKey: 'footer-upcoming-festivals',
        },
        method: 'get',
    });

    useEffect(() => {
        if (!loading && !error && data) {
            const festivals: Festival[] = data?.data;
            setResults(festivals);
        }
    }, [data, error, loading]);

    return results?.length > 0 ? (
        <div>
            {showTitle ? (
                <h4 className={cx('__col-header')}>Upcoming Music Festivals</h4>
            ) : null}
            <ul className={cx('__col-links')}>
                {results.map((result: Festival) => (
                    <li key={`footer-festival-${result.id}`}>
                        <Link
                            href={`/festivals/${result.slug}`}
                            className={cx('__col-link')}>
                            {result.name}
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    ) : null;
};

export default UpcomingFestivals;
