import { useEffect, useState } from 'react';
import Link from 'next/link';
import classNames from 'classnames/bind';

import useHTTP from '@/hooks/useHTTP';

const cx = classNames.bind(styles);

import styles from './styles.module.scss';

interface Props {
    maxResults: number;
    showTitle?: boolean;
}

const PopularLocations: React.FC<Props> = ({
    maxResults,
    showTitle = true,
}) => {
    const [results, setResults] = useState<GroupedVenue[]>([]);

    const { data, loading, error } = useHTTP({
        request: {
            endpoint: '/venues/grouped',
            cache: true,
            params: {
                _limit: maxResults,
            },
        },
        method: 'get',
    });

    useEffect(() => {
        if (!loading && !error && data) {
            const venues: GroupedVenue[] = data?.data;
            setResults(venues);
        }
    }, [data, error, loading]);

    return results?.length > 0 ? (
        <div>
            {showTitle ? (
                <h4 className={cx('__col-header')}>
                    Popular Festival Locations
                </h4>
            ) : null}
            <ul className={cx('__col-links')}>
                {results.map((result: GroupedVenue) => (
                    <li key={`footer-country-${result.countryId}`}>
                        <Link
                            href={`/locations/${result.countrySlug}`}
                            className={cx('__col-link')}>
                            {result.country}
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    ) : null;
};

export default PopularLocations;
