import Link from 'next/link';
import classNames from 'classnames/bind';

import Account from '@/components/chrome/footer/Account';
import Disclosure from '@/components/containers/disclosure/Disclosure';
import FeaturedGenres from '@/components/chrome/footer/FeaturedGenres';
import FollowUs from '@/components/chrome/footer/FollowUs';
import MainLinks from '@/components/chrome/footer/MainLinks';
import PopularLocations from '@/components/chrome/footer/PopularLocations';
import UpcomingFestivals from '@/components/chrome/footer/UpcomingFestivals';
import Support from '@/components/chrome/footer/Support';
import TouringArtists from '@/components/chrome/footer/TouringArtists';
import useDevice from '@/hooks/useDevice';
import { AuthState, selectAuth } from '@/store/auth';
import { useAppSelector } from '@/store/hooks';

import styles from './styles.module.scss';
import SmartImage from '@/components/image/SmartImage';

const cx = classNames.bind(styles);

const FOOTER_MAX_ROWS = 8;

const AppFooter = () => {
    const { authenticated } = useAppSelector<AuthState>(selectAuth);
    const { isMobile, isTablet, calculating } = useDevice();

    const getYear = () => new Date().getFullYear();

    return (
        <footer
            id='app-footer'
            className={cx(
                'footer',
                'page__gutter',
                {
                    '--authenticated': authenticated,
                },
                { '--notauthenticated': !authenticated }
            )}>
            <div className={cx('__row-cols')}>
                <div className={cx('__main-row')}>
                    <div className={cx('brand')}>
                        <Link
                            href='/'
                            className={cx('logo')}
                            aria-label='FestGPS'>
                            <SmartImage
                                src='/images/global/festgps-v.svg'
                                alt='FestGPS'
                                width='256'
                                height='57'
                            />
                        </Link>
                    </div>
                </div>
                {(isMobile || isTablet) && !calculating ? (
                    <div className={cx('__disclosure-row')}>
                        <Disclosure heading='Popular Music Genres'>
                            <FeaturedGenres
                                maxResults={FOOTER_MAX_ROWS}
                                showTitle={false}
                            />
                        </Disclosure>
                        <Disclosure heading='Upcoming Music Festivals'>
                            <UpcomingFestivals
                                maxResults={FOOTER_MAX_ROWS}
                                showTitle={false}
                            />
                        </Disclosure>
                        <Disclosure heading='Touring Artists'>
                            <TouringArtists
                                maxResults={FOOTER_MAX_ROWS}
                                showTitle={false}
                            />
                        </Disclosure>
                        <Disclosure heading='Popular Festival Locations'>
                            <PopularLocations
                                maxResults={FOOTER_MAX_ROWS}
                                showTitle={false}
                            />
                        </Disclosure>
                        <div className={cx('__divider')}></div>
                        <Disclosure heading='FestGPS'>
                            <MainLinks showTitle={false} />
                        </Disclosure>
                        <Disclosure heading='Support'>
                            <Support
                                authenticated={authenticated}
                                showTitle={false}
                            />
                        </Disclosure>
                        {!authenticated ? (
                            <Disclosure heading='Account'>
                                <Account showTitle={false} />
                            </Disclosure>
                        ) : null}
                        <Disclosure heading='Follow Us'>
                            <FollowUs showTitle={false} />
                        </Disclosure>
                    </div>
                ) : null}
                {!isMobile && !isTablet && !calculating ? (
                    <div className={cx('__links-row')}>
                        <div className={cx('__top-row')}>
                            <div className={cx('__col')}>
                                <FeaturedGenres maxResults={FOOTER_MAX_ROWS} />
                            </div>
                            <div className={cx('__col')}>
                                <UpcomingFestivals
                                    maxResults={FOOTER_MAX_ROWS}
                                />
                            </div>
                            <div className={cx('__col')}>
                                <TouringArtists maxResults={FOOTER_MAX_ROWS} />
                            </div>
                            <div className={cx('__col')}>
                                <PopularLocations
                                    maxResults={FOOTER_MAX_ROWS}
                                />
                            </div>
                        </div>
                        <div className={cx('__mid-row')}>
                            <div className={cx('__col')}>
                                <MainLinks />
                            </div>
                            <div className={cx('__col')}>
                                <Support authenticated={authenticated} />
                            </div>
                            {!authenticated ? (
                                <div className={cx('__col')}>
                                    <Account />
                                </div>
                            ) : null}
                            <div className={cx('__col')}>
                                <FollowUs />
                            </div>
                            {authenticated ? (
                                <div className={cx('__col')}></div>
                            ) : null}
                        </div>
                    </div>
                ) : null}
            </div>
            <div className={cx('__bottom-row')}>
                <p className={cx('__text')}>
                    &copy;{getYear()} Akashic Record Crate d.o.o. All rights
                    reserved.
                </p>
            </div>
        </footer>
    );
};

export default AppFooter;
